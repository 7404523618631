<template>
  <div>
    <div class="contact--itemWrap">
      <h3>{{ title }}</h3>

      <div v-for="(contact, i) in contactList" :key="i">
        <p v-if="contact.address">
          {{ contact.address }}
        </p>
        <div class="contact--item" v-if="contact.email">
          <span class="icon-envelope"></span>
          <a :href="'mailto:' + contact.email" class="">{{ contact.email }}</a>
        </div>
        <div class="contact--item" v-if="contact.tel">
          <span class="icon-phone"></span>
          <a :href="'tel:' + contact.tel" class="">{{ contact.tel }}</a>
        </div>
        <div class="contact--item" v-if="contact.fax">
          <span class="icon-fax"></span>
          <div class="">
            {{ contact.fax }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "contactItem",
  data() {
    return {};
  },
  props: {
    title: String,
    contactList: Array
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
.contact {
  &--content {
    padding: 25px 0;
    @include respond-to(screen-lg) {
      padding: 50px 0;
    }
    h3 {
      line-height: 1;
      font-size: 20px;
      font-weight: 400;
      margin-bottom: 10px;
      // margin-bottom: 10px;
      @include respond-to(screen-md) {
        margin-bottom: 20px;
      }
    }
    p {
      line-height: 1.2;
      margin-bottom: 8px;
      @include respond-to(screen-md) {
        margin-bottom: 15px;
      }
    }
  }
  &--itemWrap {
    margin-bottom: 40px;
    @include respond-to(screen-md) {
      margin-bottom: 30px;
    }
  }
  &--item {
    > * {
      font-size: 14px;
      @include respond-to(screen-lg) {
        font-size: 16px;
      }
    }
    line-height: 1;
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    @include respond-to(screen-md) {
      margin-bottom: 15px;
    }
    span {
      margin-right: 5px;
    }
    a {
      @include transition(all 300ms ease-in-out);
      &:hover {
        @include opacity(0.6);
      }
    }
  }
}
</style>
